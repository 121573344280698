import constate from 'constate';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useLocalstorageState } from 'rooks';

import { AllRoutesKeys } from '@/core/navigation/use-navigation';
import {
  AreasScopesEnum,
  CultivationDataFragment,
} from '@/generated/graphql';
import { STORAGE_HISTORY_LUMA, STORAGE_KEY_LUMA } from '@/services/local-storage/keys';

import { useInfoContext } from '../use-info-context';

export type LumaPageContext = {
  name: string;
  key: AllRoutesKeys;
};

export type LumaAppContext = {
  name: string;
  location: {
    latitude: number;
    longitude: number;
  };
}

export type LumaDeviceContext = {
  name: string;
  providerId: string;
  categories: string[];
  dataForTimeframe: Array<{
    x: string;
  }>;
}

export type LumaAreaContext = {
  id: number;
  name: string;
  scope: AreasScopesEnum;
  cultivation?: CultivationDataFragment;
}

export const MAX_TIMEFRAME_DATA_HOURS = 24;

const mapInfoByPathname: Record<string, LumaPageContext> = {
  '/app/[appid]/dashboard': {
    key: 'dashboard',
    name: 'Dashboard',
  },
  '/app/[appid]/automations': {
    key: 'automations',
    name: 'Automations',
  },
  '/app/[appid]/automations/[automationid]': {
    key: 'automation-detail',
    name: 'automation detail',
  },
  '/app/[appid]/areas': {
    key: 'areas',
    name: 'Areas',
  },
  '/app/[appid]/areas/[areaid]': {
    key: 'area-detail',
    name: 'Area detail',
  },
  '/app/[appid]/areas/[areaid]/[deviceid]': {
    key: 'device-detail',
    name: 'Device detail',
  },
  '/app/[appid]/analysis': {
    key: 'analysis',
    name: 'Analysis',
  },
  '/app/[appid]/analysis/analysisslug': {
    key: 'analysis-detail',
    name: 'Analysis detail',
  },
};

type DeviceData = {
  deviceName: string;
  sampleData: Array<Record<string, string | number>>;
}

const useLuma = () => {
  const router = useRouter();
  const { info } = useInfoContext();
  const [lumaPageContext, setLumaPageContext] = useState<LumaPageContext>();
  const [lumaAppContext, setLumaAppContext] = useState<LumaAppContext>();
  const [lumaAreaContext, setLumaAreaContext] = useState<LumaAreaContext>();
  const [lumaDeviceContext, setLumaDeviceContext] = useState<LumaDeviceContext>();
  const [deviceData, setDeviceData] = useState<DeviceData>();
  const [isLumaOpened, setIsLumaOpened] = useLocalstorageState<boolean>(STORAGE_KEY_LUMA, false);
  const [isHistoryActive, setIsHistoryActive] = useLocalstorageState<boolean>(STORAGE_HISTORY_LUMA, true);

  useEffect(() => {
    if (mapInfoByPathname[router.pathname]) {
      setLumaPageContext(mapInfoByPathname[router.pathname]);
    } else {
      setLumaPageContext(mapInfoByPathname['/app/[appid]/dashboard']);
    }
  }, [router.pathname]);

  useEffect(() => {
    if (info?.currentApplication) {
      setLumaAppContext({
        name: info.currentApplication.name,
        location: {
          latitude: info.currentApplication.lat ?? 0,
          longitude: info.currentApplication.lon ?? 0,
        },
      });
    }
  }, [info]);

  return {
    lumaPageContext,
    lumaAppContext,
    lumaAreaContext,
    setLumaAreaContext,
    lumaDeviceContext,
    setLumaDeviceContext,
    deviceData,
    setDeviceData,
    isLumaOpened,
    setIsLumaOpened,
    isHistoryActive,
    setIsHistoryActive,
  };
};

export const [
  LumaContextProvider,
  useLumaContext,
] = constate(useLuma);
