
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import '@lualtek/react-components/core.css';
import '@lualtek/themes/web';
import './_app.css';
import { ResponsiveProvider, ToastProvider, TooltipProvider } from '@lualtek/react-components';
import NextProgress from 'next-progress';
import { ThemeProvider } from 'next-themes';
import { Fragment } from 'react';
import { AppChecks } from '@/components/auth/app-checks';
import { InitPublicApp } from '@/components/auth/init-app';
import { GtmComponent } from '@/components/common/shared/gtm';
import { Meta } from '@/components/common/shared/meta';
import { ColumnLayout } from '@/components/layouts/desktop/column-layout';
import { ShellLayout } from '@/components/layouts/desktop/shell-layout';
import { ColumnLayout as ColumnLayoutMobile } from '@/components/layouts/mobile/column-layout';
import { ShellLayout as ShellLayoutMobile } from '@/components/layouts/mobile/shell-layout';
import { FullLayout } from '@/components/layouts/shared/full-layout';
import { GateLayout } from '@/components/layouts/shared/gate-layout';
import { ApplicationModalContextProvider } from '@/context/use-application-modal-context';
import { CrumbDetailContextProvider } from '@/context/use-crumb-detail-context';
import { FeedbackContextProvider } from '@/context/use-feedback-context';
import { HistoryContextProvider } from '@/context/use-history-context';
import { InfoContextProvider } from '@/context/use-info-context';
import { LumaContextProvider } from '@/context/use-luma-context';
import { MobileContextProvider } from '@/context/use-mobile-context';
import { PullToRefreshContextProvider } from '@/context/use-pull-to-refresh-context';
import { useIsOffline } from '@/core/offline/use-is-offline';
import type { LayoutTypes, PagePropsType } from '@/core/page-helpers/get-page-props';
import { ApolloProvider } from '@/services/apollo/apollo-provider';
import { useInitNotification } from '@/services/notifications';
const LAYOUTS = {
    mobile: {
        gate: GateLayout,
        full: FullLayout,
        shell: ShellLayoutMobile,
        column: ColumnLayoutMobile,
        noop: Fragment,
    },
    desktop: {
        gate: GateLayout,
        full: FullLayout,
        shell: ShellLayout,
        column: ColumnLayout,
        noop: Fragment,
    },
};
export function reportWebVitals(_: any) {
    // These metrics can be sent to any analytics service
    // console.log(metric);
}
const InitLibraries: FCChildren = ({ children }) => {
    useInitNotification();
    useIsOffline();
    return <>{children}</>;
};
const Providers: FCChildren<{
    pageProps: PagePropsType<LayoutTypes>;
}> = ({ children, pageProps }) => (<ApolloProvider initialApolloState={pageProps.initialApolloState ?? {}}>
    <InfoContextProvider>
      <LumaContextProvider>
        <PullToRefreshContextProvider>
          <ResponsiveProvider>
            <MobileContextProvider initialValue={pageProps.isMobile}>
              <HistoryContextProvider>
                <ThemeProvider defaultTheme="system" enableSystem disableTransitionOnChange>
                  <TooltipProvider>
                    <FeedbackContextProvider>
                      <ApplicationModalContextProvider>
                        <CrumbDetailContextProvider>
                          <ToastProvider swipeDirection={pageProps.isMobile ? 'down' : undefined}>
                            {children}
                          </ToastProvider>
                        </CrumbDetailContextProvider>
                      </ApplicationModalContextProvider>
                    </FeedbackContextProvider>
                  </TooltipProvider>
                </ThemeProvider>
              </HistoryContextProvider>
            </MobileContextProvider>
          </ResponsiveProvider>
        </PullToRefreshContextProvider>
      </LumaContextProvider>
    </InfoContextProvider>
  </ApolloProvider>);
// biome-ignore lint/style/useNamingConvention: Component is not a standard prop but a Component
const App = ({ pageProps, Component }: {
    pageProps: PagePropsType<LayoutTypes>;
    Component: any;
}) => {
    const InitComponent = pageProps.isPublic ? InitPublicApp : Fragment;
    const CheckComponent = pageProps.isPublic ? Fragment : AppChecks;
    const InitLibrariesComponent = pageProps.isPublic ? Fragment : InitLibraries;
    const LayoutSize = pageProps.isMobile ? LAYOUTS.mobile : LAYOUTS.desktop;
    const Layout = (pageProps.layout && LayoutSize[pageProps.layout]) ?? LayoutSize.shell;
    const layoutProps = pageProps.layoutProps ?? {};
    return (<>
      <Providers pageProps={pageProps}>
        <Meta />
        <NextProgress delay={300} color="var(--cta-default)" options={{ showSpinner: false, minimum: 0.3 }}/>
        <InitComponent>
          <CheckComponent>
            <InitLibrariesComponent>
              <Layout {...layoutProps}>
                <Component {...pageProps}/>
              </Layout>
            </InitLibrariesComponent>
          </CheckComponent>
        </InitComponent>
      </Providers>
      <GtmComponent />
    </>);
};
const __Next_Translate__Page__19504d216eb__ = App;

    export default __appWithI18n(__Next_Translate__Page__19504d216eb__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  