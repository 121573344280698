import { Spinner } from '@lualtek/react-components';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useLocalstorageState } from 'rooks';

import { useApplications } from '@/api/application';
import { useUser } from '@/api/user';
import { useInfoContext } from '@/context/use-info-context';
import { useCurrentQuery } from '@/core/navigation/use-current-query';
import { STORAGE_KEY_LOCAL_APP } from '@/services/local-storage/keys';

import styles from './init-app.module.css';

export const AppChecks: FCChildren = ({ children }) => {
  const router = useRouter();
  const { loading, user } = useUser();
  const { appid: appIdQuery } = useCurrentQuery();
  const [, setLocalApp] = useLocalstorageState(STORAGE_KEY_LOCAL_APP);
  const { applications, loading: loadingApplications, currentApplication } = useApplications({ userId: user?.id });
  const { setInfo } = useInfoContext();

  useEffect(() => {
    if (user?.blocked) {
      void router.replace('/logout');
      return;
    }

    // Redirect to home if trying to access a appId which is not owned by the user
    if (applications.length > 0 && Number.isInteger(appIdQuery) && !currentApplication) {
      setLocalApp(undefined);
      void router.replace('/');
    }
  }, [appIdQuery, applications.length, currentApplication, router, setLocalApp, user]);

  useEffect(() => {
    const scrollToTop = () => {
      const scroller = document.querySelector('[data-scroller="true"]');
      scroller?.scrollTo(0, 0);
    };

    router.events.on('routeChangeComplete', scrollToTop);

    return () => {
      router.events.off('routeChangeComplete', scrollToTop);
    };
  }, [router.events]);

  useEffect(() => {
    if (currentApplication?.id) {
      setInfo({
        currentOrganizationId: currentApplication.organizationId,
        currentApplication,
      });
    }
  }, [currentApplication, setInfo]);

  return loadingApplications || loading ? <div className={styles.LoadingWall}><Spinner /></div> : <>{children}</>;
};
