/**
 * Context where to store user/application/organization infos to be available in the whole app
 */

import constate from 'constate';
import { useState } from 'react';

export type InfoProps = {
  currentOrganizationId?: number;
  currentApplication?: {
    id: number;
    name: string;
    devicesCount: number;
    automationsCount: number;
    organizationId: number;
    lat?: number | null;
    lon?: number | null;
  };

}

const useInfo = () => {
  const [info, setInfo] = useState<InfoProps | null>();

  return {
    info,
    setInfo,
  };
};

export const [
  InfoContextProvider,
  useInfoContext,
] = constate(useInfo);
