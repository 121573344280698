import {
  Button,
  InfoState, ScrollArea, Separator, Spinner, Stack,
} from '@lualtek/react-components';

import { Status } from '@/components/common/shared/status';
import { useTranslate } from '@/core/i18n';

import { LumaProps } from '../../shared/luma.type';
import { SkeletonMessages } from '../../shared/skeleton-messages';
import { useLumaComponents } from '../../shared/use-luma-components';
import { useLumaLogic } from '../../shared/use-luma-logic';
import styles from './luma.module.css';

export const Luma: FCChildren<LumaProps> = ({
  featureLimits,
  hasTokens,
  loadingTokens,
  historyMessages,
  isHistoryLoading,
  fetchMoreMessages,
  lastFetchHistoryMessages,
  loadingFetchMore,
  chatHelpers,
  children,
  organizationChatParams,
  isAssistantStartToGenerate,
  setIsHistoryActive,
  isHistoryActive,
  deleteChatMessages,
  loadingDeleteChatMessages,
  ...otherProps
}) => {
  const { t } = useTranslate();

  const {
    isLoading,
  } = chatHelpers;

  const {
    shouldAlert,
    hasMessages,
    queryPresets,
    onSubmit,
    onClickPreset,
    onExpandPreset,
    shouldShowAllPresets,
    messagesRef,
    handleJumpToBottom,
    innerMessagesRef,
    isShowJumpButton,
  } = useLumaLogic({
    historyMessages,
    lastFetchHistoryMessages,
    featureLimits,
    organizationChatParams,
    chatHelpers,
    loadingFetchMore,
    fetchMoreMessages,
  });

  const {
    Presets, Messages, Disclaimer, Input, HistoryLimitReachedMessage,
    InfoStateActions,
  } = useLumaComponents({
    deleteChatMessages,
    onClickPreset,
    queryPresets,
    shouldShowAllPresets,
    onExpandPreset,
    hasTokens,
    shouldAlert,
    chatHelpers,
    hasMessages,
    onSubmit,
    featureLimits,
    setIsHistoryActive,
    isHistoryActive,
    loadingDeleteChatMessages,
  });

  return (
    <Stack vAlign="space-between" fill={false} className={styles.Luma} {...otherProps}>
      <Stack
        className={styles.ChatWrapper}
        vPadding={hasMessages ? undefined : 40}
        rowGap={hasMessages ? undefined : 32}
        fill={false}
        vAlign={isHistoryLoading ? 'start' : 'center'}
      >
        <Status
          EmptyComponent={(
            <Stack hPadding={24}>
              <InfoState
                title={t('luma:infoState.title')}
                icon="ai-chat"
                iconColor="blue"
                actions={(
                  <Stack hPadding={24}>
                    {InfoStateActions}
                  </Stack>
                )}
              >
                {t('luma:infoState.text')}
              </InfoState>
            </Stack>
          )}
          empty={!hasMessages}
          LoadingComponent={<div><SkeletonMessages /></div>}
          loading={isHistoryLoading}
        >
          <ScrollArea
            as={Stack}
            hPadding={24}
            ref={messagesRef}
            vPadding={24}
            canScroll={!isLoading}
            className={styles.Messages}
            fadeDirection="vertical"
            useSystemStyle={false}
            fill={false}
            thumbColor="var(--dimmed-4)"
          >
            {loadingFetchMore && <Stack vPadding={24}><SkeletonMessages /></Stack>}
            <Stack
              as="ul"
              rowGap={24}
              fill={false}
              vAlign="start"
              hAlign="end"
              ref={innerMessagesRef}
            >
              {HistoryLimitReachedMessage}
              {Messages}
              {isAssistantStartToGenerate && (
                <Stack
                  as="li"
                  hAlign="center"
                  style={{ width: '100%' }}
                  fill={false}
                >
                  <Spinner dimension="regular" />
                </Stack>
              )}
            </Stack>

            {!isLoading && !loadingTokens && hasTokens && (
              <>
                <Separator vPadding={16} />
                {Presets}
              </>
            )}

            {isShowJumpButton && (
              <Stack hAlign="center" vPadding={8} className={styles.Jump}>
                <Button
                  icon="arrow-down"
                  onClick={handleJumpToBottom}
                  dimension="small"
                  iconPosition="end"
                >
                  {t('luma:messages.jumpDown')}
                </Button>
              </Stack>
            )}
          </ScrollArea>
        </Status>
      </Stack>
      {!loadingTokens ? Input : null}
      {Disclaimer}
    </Stack>
  );
};

Luma.displayName = 'Luma';
