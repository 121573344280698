import { Message } from 'ai';
import {
  useCallback, useEffect, useMemo, useRef,
  useState,
} from 'react';

import {
  ChatMessageDataFragment,
  useChatMessagesSubscription,
  useDeleteChatMessagesMutation,
  useGetChatMessagesQuery,
  useOrganizationChatParamsSubscription,
} from '@/generated/graphql';
import { extractError } from '@/services/apollo/extract-error';

import { HookProps } from '../types';

type UseGetChatMessagesProps = {
  userId?: number;
  organizationId?: number;
  startDate?: string;
  endDate?: string;
  maxChatHistoryMessages: number;
}

const CHAT_MESSAGES_LIMIT = 30;

const mapChatMessages = (messages: ChatMessageDataFragment[]): Message[] => messages.slice().reverse().map(({
  id, isUserMessage, content, createdAt,
  // uuid,
}) => ({
  id: String(id),
  role: isUserMessage ? 'user' : 'assistant',
  content,
  createdAt: new Date(createdAt),
  // data: {
  //   uuid,
  // }
}));

export const useGetChatMessages = ({
  organizationId,
  userId,
  maxChatHistoryMessages,
}: UseGetChatMessagesProps, props?: HookProps) => {
  const {
    data,
    loading,
    fetchMore,
    error,
    refetch,
  } = useGetChatMessagesQuery({
    variables: {
      offset: 0,
      userId: userId ?? 0,
      organizationId: organizationId ?? 0,
      limit: CHAT_MESSAGES_LIMIT,
    },
    fetchPolicy: 'cache-and-network',
    onError: error => props?.onError?.(extractError(error)),
    skip: !organizationId || !userId,
  });
  const [loadingFetchMore, setLoadingFetchMore] = useState(false);
  const fetchMoreCount = useRef(-1);

  const fetchMoreMessages = useCallback(
    async (props?: { offset?: number }) => {
      if (fetchMoreCount.current === 0) {
        return;
      }

      if ((data?.chatMessages
        && data.chatMessages.length > maxChatHistoryMessages) || loadingFetchMore) {
        return;
      }

      setLoadingFetchMore(true);
      const { data: fetchMoreData } = await fetchMore({
        variables: {
          offset: props?.offset ?? data?.chatMessages?.length ?? 0,
        },
      });

      fetchMoreCount.current = fetchMoreData.chatMessages.length;
      setLoadingFetchMore(false);
    },
    [fetchMore, data?.chatMessages, loadingFetchMore, maxChatHistoryMessages],
  );

  const lastFetchChatMessages = useMemo(() => {
    const hasFetched = data?.chatMessages && data.chatMessages.length > CHAT_MESSAGES_LIMIT;
    const hasMoreToShow = fetchMoreCount.current > 0;
    if (hasFetched && hasMoreToShow) {
      return data.chatMessages.slice(-fetchMoreCount.current);
    }

    return [];
  }, [data?.chatMessages]);

  return {
    loading,
    chatMessages: data?.chatMessages ?? [],
    fetchMoreMessages,
    loadingFetchMore,
    lastFetchChatMessages,
    refetch,
    error,
  };
};

export const useOrganizationChatParamsSubs = ({ organizationId, onData }: {
  onData?: () => void;
  organizationId?: number;
}) => {
  const { data, loading, error } = useOrganizationChatParamsSubscription({
    variables: {
      organizationId: organizationId ?? 0,
    },
    onData: () => onData?.(),
  });

  return {
    loading,
    organizationChatParams: data?.organizationsChatParams?.at(0) ?? null,
    error,
  };
};

export const useGetChatMessagesSub = ({
  onData,
  excludeFirstLoad = false,
  organizationId,
  userId,
}: {
  onData?: () => void;
  excludeFirstLoad?: boolean;
  organizationId?: number;
  userId?: number;
  startDate?: string;
}) => {
  const firstLoadRef = useRef(true);
  const {
    data,
    loading,
    error,
  } = useChatMessagesSubscription({
    variables: {
      userId: userId ?? 0,
      organizationId: organizationId ?? 0,
      startDate: new Date().toISOString(),
    },
    skip: !organizationId || !userId,
    onData: () => onData?.(),
  });

  const dataParsed = useMemo(() => mapChatMessages(data?.chatMessages ?? []), [data]);

  useEffect(() => {
    if (excludeFirstLoad && firstLoadRef.current && data) {
      firstLoadRef.current = false;
    }
  }, [data, excludeFirstLoad]);

  return {
    loading,
    chatMessages: excludeFirstLoad && firstLoadRef.current ? [] : dataParsed ?? [],
    error,
  };
};

export const useChatMutations = () => {
  const [deleteChat, { loading, error }] = useDeleteChatMessagesMutation({
    refetchQueries: [],
  });

  const deleteChatMessages = useCallback(
    async ({
      userId,
    }: {
      userId: number;
    }, props?: HookProps) => deleteChat({
      variables: {
        userId,
      },
      onError: error => props?.onError?.(extractError(error)),
    }),
    [deleteChat],
  );

  return {
    deleteChatMessages,
    loading,
    error,
  };
};
