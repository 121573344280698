import { useChat } from 'ai/react';
import { useCallback, useMemo } from 'react';

import { useGetChatMessages, useOrganizationChatParamsSubs } from '@/api/chat';
import { useChatMutations } from '@/api/chat/chat';
import { useFeatureLimits } from '@/api/organization';
import { useUser } from '@/api/user';
import { useFeedbackContext } from '@/context/use-feedback-context';
import { useInfoContext } from '@/context/use-info-context';
import { useLumaContext } from '@/context/use-luma-context';
import { useTranslate } from '@/core/i18n';
import { getHeaders } from '@/services/apollo/get-headers';

export const useLumaWrapperLogic = () => {
  const { user } = useUser();
  const { t } = useTranslate();
  const { info } = useInfoContext();
  const { addFeedback } = useFeedbackContext();
  const {
    lumaAppContext,
    lumaPageContext,
    lumaAreaContext,
    lumaDeviceContext,
    isHistoryActive,
    setIsHistoryActive,
  } = useLumaContext();

  const { deleteChatMessages, loading } = useChatMutations();

  const { featureLimits, featureLimitsParsed } = useFeatureLimits();
  const {
    // error,
    organizationChatParams,
    loading: loadingTokens,
  } = useOrganizationChatParamsSubs({
    organizationId: info?.currentOrganizationId ?? 0,
  });
  const hasTokens = useMemo(
    () => (Number(featureLimits?.maxChatTokensMonth) - Number(organizationChatParams?.tokensMonthUsed ?? 0)) > 0,
    [featureLimits, organizationChatParams?.tokensMonthUsed],
  );
  const {
    chatMessages,
    lastFetchChatMessages,
    loading: isHistoryLoading,
    fetchMoreMessages,
    loadingFetchMore,
    // refetch,
  } = useGetChatMessages({
    userId: user?.id ?? 0,
    organizationId: info?.currentOrganizationId ?? 0,
    maxChatHistoryMessages: featureLimitsParsed.maxChatHistoryMessages,
  });

  // const { chatMessages: chatMessageSub } = useGetChatMessagesSub({
  //   organizationId: info?.currentOrganizationId ?? 0,
  //   userId: user?.id ?? 0,
  // });

  const chatHelpers = useChat({
    api: '/api/ai/chat',
    body: {
      lumaAppContext,
      lumaPageContext,
      lumaAreaContext,
      lumaDeviceContext,
      userId: user?.id ?? 0,
      organizationId: info?.currentOrganizationId ?? 0,
      withHistory: isHistoryActive,
    },
    headers: {
      ...getHeaders(),
      'x-hasura-user-id': String(user?.id) ?? '',
    },
    experimental_throttle: 150,
  });

  const isLastMessageFromAssistant = useMemo(() => {
    const lastMessage = chatHelpers.messages[chatHelpers.messages.length - 1];
    return lastMessage?.role === 'assistant';
  },
  [chatHelpers.messages]);

  const onDelete = useCallback(async () => {
    if (!user?.id) {
      return;
    }

    const isSuccess = await deleteChatMessages({
      userId: user.id,
    }, {
      onError: () => {
        addFeedback({
          title: t('luma:feedback.delete.error.title'),
          children: t('luma:feedback.delete.error.message'),
          kind: 'danger',
        });
      },
    });

    if (isSuccess) {
      addFeedback({
        title: t('luma:feedback.delete.success.title'),
        children: t('luma:feedback.delete.success.message'),
      });
      chatHelpers.setMessages([]);
    }
  }, [user?.id, deleteChatMessages, addFeedback, t, chatHelpers]);

  const lumaProps = useMemo(() => ({
    featureLimits,
    hasTokens,
    loadingTokens,
    lastFetchHistoryMessages: lastFetchChatMessages,
    isHistoryLoading,
    historyMessages: chatMessages,
    fetchMoreMessages,
    loadingFetchMore,
    isAssistantStartToGenerate: chatHelpers.isLoading && !isLastMessageFromAssistant,
    chatHelpers,
    organizationChatParams,
    setIsHistoryActive,
    isHistoryActive,
    deleteChatMessages: onDelete,
    loadingDeleteChatMessages: loading,
  }), [
    loading,
    featureLimits,
    hasTokens,
    loadingTokens,
    lastFetchChatMessages,
    isHistoryLoading,
    chatMessages,
    fetchMoreMessages,
    loadingFetchMore,
    isLastMessageFromAssistant,
    chatHelpers,
    organizationChatParams,
    setIsHistoryActive,
    isHistoryActive,
    onDelete,
  ]);

  return {
    lumaProps,
  };
};
