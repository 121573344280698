import {
  Button, Modal, Stack, Text,
} from '@lualtek/react-components';

import { useTranslate } from '@/core/i18n';

export type SharedDeleteModalProps = {
  isOpen: boolean;
  onDismiss: () => void;
  onDelete: () => void;
  loading?: boolean;
}

type DeleteModalProps = SharedDeleteModalProps & {
  title: string;
  subtitle?: string;
  content: React.ReactNode;
}

export const DeleteModal: FCClass<DeleteModalProps> = ({
  isOpen,
  onDismiss,
  onDelete,
  title,
  subtitle,
  content,
  className,
  loading,
}) => {
  const { t } = useTranslate();
  return (
    <Modal isOpen={isOpen} onClose={onDismiss} className={className}>
      <Modal.Content heading={title}>
        <Stack hPadding={24} vPadding={40} rowGap={40}>
          <Text align="center">
            {subtitle && (
              <>
                {subtitle}
                <br />
              </>
            )}
            {content}
          </Text>

          <Stack direction="row" columnGap={8} hAlign="center" fill={false}>
            <Button
              kind="flat"
              onClick={onDismiss}
              disabled={loading}
            >
              {t('common:cta.cancel')}
            </Button>
            <Button
              onClick={onDelete}
              sentiment="danger"
              disabled={loading}
              busy={loading}
              data-testid="delete-confirm"
            >
              {t('common:cta.confirm')}
            </Button>
          </Stack>
        </Stack>
      </Modal.Content>
    </Modal>
  );
};
